import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { dashboard as dashboardRoutes, page as pageRoutes, def as defaultRoutes } from "./index";
import {Auth} from 'aws-amplify'
import {API} from '../utils/utils'
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import {setUser,setIsLogged} from '../redux/actions/rootActions'
import ScrollToTop from "../components/ScrollToTop";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const childRoutes = (Layout, routes,setIsLogged) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout setIsLogged={setIsLogged}>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout setIsLogged={setIsLogged}>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

const Routes = (props) =>{
const [isLogged, setIsLogged] = React.useState(false)


React.useEffect(async() => {
  
  try {
    let res=await Auth.currentSession();
    
    
    let role = (await Auth.currentAuthenticatedUser()).attributes[
      "custom:Role"
    ].toLowerCase();
    
    const response = (await API.get("profile")).data;
    
    
    props.setUser(response.Item)
    props.setIsLogged(true)
      !document.querySelector(".splash") ||
document.querySelector(".splash").classList.remove("active");
    
    
  }
  catch(e) {
    if (e !== 'No current user') {
      
    }
    !document.querySelector(".splash") ||
document.querySelector(".splash").classList.remove("active")
  }
}, [])


return(
  <Router>
    <ScrollToTop>
      <Switch>
        {props.root.logged===true?

        
        childRoutes(DashboardLayout, dashboardRoutes)
        :
        childRoutes(AuthLayout, pageRoutes)
}
{childRoutes(DashboardLayout,defaultRoutes)}

      </Switch>
    </ScrollToTop>
  </Router>
);
}


const mapStateToProps = (state: any) => {
  const { user,root } = state;
  return { user,root };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setUser,
      setIsLogged
    },
    dispatch
  );



export default connect(mapStateToProps,mapDispatchToProps)(Routes);
