import constants from './constants'

export const amplifyConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: constants.awsRegion,
    identityPoolId: constants.identityPoolId,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: constants.userPoolId,
    userPoolWebClientId: constants.userPoolWebClientId,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or n ot
    mandatorySignIn: false,
  },
  Storage: {
    AWSS3: {
      bucket: constants.s3PublicFiles, //REQUIRED -  Amazon S3 bucket name
      region: constants.awsRegion //OPTIONAL -  Amazon service region
    },
  },
}