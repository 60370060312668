import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { toastr } from "react-redux-toastr";
import { Auth } from "aws-amplify";
import Spinner from "reactstrap/lib/Spinner";
const ResetPassword = (props) => {
  const [email, setEmail] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [OTP, setOTP] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false)

  const sendCode = async () => {
    setLoading(true)
    try {
      let res = await Auth.forgotPassword(email);
      console.log(res);

      setShowOTP(true);
    } catch (e) {
      console.log(e);
      if(e.code){
        showErrorToastr(e.code)
      }
    }
    setLoading(false)
  };

  useEffect(() => {
    console.log(OTP);
    if (OTP.length === 6) {
      setShowPassword(true);
    }
  }, [OTP]);

  const showSuccessToastr = () => {
    const options = {
      timeOut: parseInt(5000),
      showCloseButton: true,
      progressBar: false,
      position: "top-right",
    };

    const toastrInstance = toastr.success;

    toastrInstance("Hecho", "Se ha cambiado tu contraseña, ya puedes iniciar sesión con tus nuevos datos", options);
  };

  const showErrorToastr = (code) => {
    console.log(code)
    let str="Ha ocurrido un error"
    if(code==="CodeMismatchException"){
      str="El código de verificación no coincide"
      setOTP("")
      setShowPassword(false)
      setShowOTP(true)
    }
    else if(code==="PasswordDontMatch"){
      str="Las contraseñas no coinciden"
    }
    else if(code==="PasswordRequirements"){
      str="La contraseña debe contener una mayúscula, un número y contener al menos 6 caracteres"
    }
    else if(code==="UserNotFoundException"){
      str="No se encuentra ningun usuario con este correo electrónico"
    }
    
    const options = {
      timeOut: parseInt(5000),
      showCloseButton: true,
      progressBar: false,
      position: "top-right",
    };

    const toastrInstance = toastr.error;

    toastrInstance("Error", str, options);
  };

  const savePassword=async()=>{
    setLoading(true)
    if(password!==confirmPassword){
      showErrorToastr("PasswordDontMatch")
      setLoading(false)
      return
    }
    if(/^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[.!@#$%^&*])[\w!.@#$%^&*]{8,20}$/.test(password)===false){
      showErrorToastr("PasswordRequirements")
      setLoading(false)
      return
    }
    try {
      let res = await Auth.forgotPasswordSubmit(email,OTP,password);
      console.log(res);
      showSuccessToastr()
      props.history.push('/auth/login')
    } catch (e) {
      console.log(e);
      if(e.code){
        showErrorToastr(e.code)
      }
    }
    setLoading(false)
  }
  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Recuperar contaseña</h1>
        {
          showOTP===true?
          showPassword===false?
          <p className="lead">
          Ingresa el código que te hemos enviado por correo
        </p>
        :
        <p className="lead">
          Ingresa tu nueva contraseña
        </p>
        :
        <p className="lead">
          Ingresa tu email y te enviaremos instrucciones para recuperar el
          acceso a tu cuenta
        </p>

        }
      </div>

      <Card>
        <CardBody>
          {
          
          loading===false?(
          showOTP === true ? (
            <div className="m-sm-4">
              <Form>
                  {showPassword === false ? (
                <div className="text-center" style={{display:"flex",justifyContent:"center"}}>
                    <OtpInput
                      value={OTP}
                      onChange={(e) => {
                        setOTP(e);
                      }}
                      numInputs={6}
                      containerStyle={{ width: 300,height:200,display:"flex",justifyContent:"center" }}
                      inputStyle={{ width: 50, height: 50,margin:10 }}
                      // separator={<span>&nbsp;</span>}
                    />
                    </div>
                  ) : (
                    <div >
                      <FormGroup>
                  <Label>Contraseña</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="password"
                    placeholder="Ingresa tu contraseña"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Confirmar Contraseña</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="confirm"
                    placeholder="Confirma tu contraseña"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </FormGroup>
                <div className="text-center" style={{marginTop:20}}>
                      <Button onClick={savePassword} color="primary" size="lg">
                        Guardar nueva contraseña
                      </Button>
                      </div>
                    </div>
                  )}
                
              </Form>
            </div>
          ) : (
            <div className="m-sm-4">
              <Form>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    bsSize="lg"
                    type="email"
                    name="email"
                    placeholder="Ingresa tu correo"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </FormGroup>
                <div className="text-center mt-3">
                  <Button onClick={sendCode} color="primary" size="lg">
                    Continuar
                  </Button>
                </div>
              </Form>
            </div>
          )
         
          )
          :
          <div className="text-center" style={{marginTop:"20%",marginBottom:"20%"}}>
          <Spinner color="primary" />
          </div>
        
        }
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ResetPassword;
