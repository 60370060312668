import {
  faArrowAltCircleRight,
  faCog,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Auth } from "aws-amplify";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import { bindActionCreators } from "redux";
import { setIsLogged, setUser } from "../redux/actions/rootActions";
import { toggleSidebar } from "../redux/actions/sidebarActions";

const NavbarToggle = connect((store) => ({
  app: store.app,
}))(({ dispatch }) => {
  return (
    <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => {
        dispatch(toggleSidebar());
      }}
    >
      <i className="hamburger align-self-center" />
    </span>
  );
});

const NavbarDropdowns = connect((store) => ({
  sidebar: store.sidebar,
}))((props) => {
  return (
    <Collapse navbar>
      <Nav className={!props.sidebar.isOnRight ? "ml-auto" : "mr-auto"} navbar>
        <UncontrolledDropdown nav inNavbar className="ml-lg-1">
          <DropdownToggle nav caret>
            <FontAwesomeIcon icon={faCog} className="align-middle" />
          </DropdownToggle>
          <DropdownMenu right>
            {/* <DropdownItem>
              <Link to="/my-profile">
                <FontAwesomeIcon
                  icon={faUser}
                  fixedWidth
                  className="mr-2 align-middle"
                />
                Mi Perfil
              </Link>
            </DropdownItem> */}
            <DropdownItem/>
            <DropdownItem>
              {/* <Link to='/auth/login'> */}
              <div onClick={props.onSignOut}>
                <FontAwesomeIcon
                  icon={faArrowAltCircleRight}
                  fixedWidth
                  className="mr-2 align-middle"
                />
                Cerrar sesión
              </div>
              {/* </Link> */}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Collapse>
  );
});

const NavbarComponent = (props) => {
  const signOut = async () => {
    console.log("xdjdshgfjsdhgfjh", props);
    const user = await Auth.signOut();
    props.setUser({});
    props.setIsLogged(false);
    // console.log(history)
    // history.push('/users')
  };
  return (
    <Navbar expand className="navbar-theme">
      <React.Fragment>
        <NavbarDropdowns onSignOut={signOut} />
        {/* <NavbarSearch /> */}
        <NavbarToggle />
      </React.Fragment>
    </Navbar>
  );
};

const mapStateToProps = (state: any) => {
  const { user, root } = state;
  return { user, root };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setUser,
      setIsLogged,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NavbarComponent);
