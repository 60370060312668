import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { bindActionCreators } from "redux";
import logo from "../../assets/img/logo.png";
import { setIsLogged, setUser } from "../../redux/actions/rootActions";
import { API } from "../../utils/utils";
import { toastr } from "react-redux-toastr";
const SignIn = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const signIn = async () => {
    setIsLoading(true);
    try {
      const user = await Auth.signIn({ username: email, password: password });
      console.log();
      if(user.attributes["custom:Role"]==="ADMIN"){
        const response = (await API.get("profile")).data;

      props.setUser(response.Item);
      props.setIsLogged(true);
      }
      else{
        showErrorToastr("UserNotAdmin")
      }
    } catch (e) {
      console.log(e);
      // if (e.code === "UserNotFoundException") {
        showErrorToastr(e.code)
      // }
    }
    setIsLoading(false);
  };



  const showErrorToastr = (code) => {
    console.log(code)
    let str="Ha ocurrido un error"
    if(code==="UserNotAdmin"){
      str="El usuario ingresado no es un administrador"
    }
    else if(code==="UserNotFoundException"){
      str="No se encuentra ningún usuario registrado con ese correo"
    }
    else if(code==="NotAuthorizedException"){
      str="El usuario o la contraseña son incorrectos"
    }
    // else if(code==="UserNotFoundException"){
    //   str="No se encuentra ningun usuario con este correo electrónico"
    // }
    
    const options = {
      timeOut: parseInt(5000),
      showCloseButton: true,
      progressBar: false,
      position: "top-right",
    };

    const toastrInstance = toastr.error;

    toastrInstance("Error", str, options);
  };

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2>Bienvenido</h2>
        <p className="lead">Inicia sesión con tu cuenta para continuar</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                src={logo}
                alt="user"
                className="img-fluid"
                width="152"
                height="152"
              />
            </div>
            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  bsSize="lg"
                  type="email"
                  name="email"
                  placeholder="Ingresa tu email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Contraseña</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="password"
                  placeholder="Ingresa tu contraseña"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <small>
                <Link to="/auth/reset-password">¿Olvidó su contraseña?</Link>
              </small>
              </FormGroup>

              <div className="text-center mt-3">
                {/* <Link to="/" */}
                <Button
                  variant="primary"
                  disabled={isLoading}
                  onClick={signIn}
                  color="primary"
                  size="lg"
                >
                  {isLoading ? "Cargando…" : "Iniciar Sesión"}
                </Button>

                {/* </Link> */}
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const { user } = state;
  return { user };
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setUser,
      setIsLogged,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
