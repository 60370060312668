import * as types from "../constants";

const INITIAL_STATE = {
    logged:false,
    user:{},
    event:{},
    userSelected:{}
  };

export default function rootReducer(state = INITIAL_STATE, action) {
    let newState={};
    switch (action.type) {
        case types.SET_USER:
            let user = action.payload
            newState = { ...state,user }
            return newState
        case types.SET_IS_LOGGED:
            let logged = action.payload
            newState = { ...state,logged }
            return newState
        case types.SET_EVENT:
            let event = action.payload
            newState = { ...state,event }
            return newState
        case types.SET_USER_SELECTED:
            let userSelected=action.payload
            newState = { ...state,userSelected }
            return newState
      default:
        return state
    }
  };