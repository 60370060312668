import {
  faBell, faFile, faHome, faImage, faMusic,
  faSignInAlt, faUser
} from "@fortawesome/free-solid-svg-icons";
import async from "../components/Async";
import ResetPassword from "../pages/auth/ResetPassword";
// Auth
import SignIn from "../pages/auth/SignIn";
// Landing
import Landing from "../pages/landing/Landing";




const Users = async(() => import("../components/Users/Users"));
const Admins = async(() => import("../components/Users/Admins"));
const AddAdmin = async(() => import("../components/Users/AddAdmin"));
const UserDetail = async(() => import("../components/Users/UserDetail"));
const Notifications = async(() => import("../components/Notifications/Notifications"));
const NotificationHistory = async(() => import("../components/Notifications/NotificationHistory"));
const Clients = async(() => import("../components/Users/Clients"));
const Restaurants = async(() => import("../components/Users/Restaurants"));
const Reports = async(() => import("../components/Reports/Reports"));
const Events = async(() => import("../components/Events/Events"));
const Services = async(() => import("../components/Events/Services"));
const Banners = async(() => import("../components/Banners/Banners"));
const ReportDetail = async(() => import("../components/Reports/ReportDetail"));
const EventDetail = async(() => import("../components/Events/EventDetail"));


// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));

// Routes
const landingRoutes = {
  path: "/landing",
  name: "Landing Page",
  component: Landing,
  children: null,
};

const dashboardRoutes = {
  path: "/",
  name: "Dashboard",
  header: "Inicio",
  icon: faHome,
  component: Default,
  children: null
};
const notificationRoutes={
  path: "/notifications",
  name: "Notificaciones",
  header: "Notificaciones",
  icon: faBell,
  component: Notifications,
  children:[
    {
      path: "/notifications",
      name: "Notificaciones",
      component: Notifications,
    },
    {
      path: "/notifications/history",
      name: "Historial",
      component: NotificationHistory,
    }
  ]
}


const profileRoutes={
  path: "/my-profile",
  name: "Mi Perfil",
  header: "Mi Perfil",
  icon: faUser,
  show:false,
  component: Events,
  children: []
}
const userRoutes={
  path: "/users",
  name: "Usuarios",
  header: "Usuarios",
  icon: faUser,
  component: Users,
  children: [
    {
      path: "/users/mariachis",
      name: "Mariachis",
      component: Users,
    },
    {
      path: "/users/admins",
      name: "Admins",
      component: Admins,
    },
    {
      path: "/users/admins/add",
      name: "Agregar Admin",
      component: AddAdmin,
      show:false
    },
    {
      path: "/users/customers",
      name: "Clientes",
      component: Clients,
    },
    {
      path: "/users/restaurants",
      name: "Restaurants",
      component: Restaurants,
    },
    {
      path: "/users/detail/:role/:id",
      name: "Detalles de Usuario",
      component: UserDetail,
      show:false
    },
    
  ],
}

const reportRoutes={
  path: "/reports",
  name: "Reportes",
  header: "Reportes",
  icon: faFile,
  component: Reports,
  children: [
    {
      path: "/reports",
      name: "Reportes",
      component: Reports,
    },
    {
      path: "/reports/detail/:id",
      name: "Detalles de reporte",
      component: ReportDetail,
      show:false
    }
  ],
}
const eventRoutes={
  path: "/events",
  name: "Eventos",
  header: "Eventos",
  icon: faMusic,
  component: Events,
  children: [
    {
      path: "/events",
      name: "Eventos",
      component: Events,
    },
    {
      path: "/services",
      name: "Servicios",
      component: Services,
    },
    {
      path: "/events/detail/:id/:date",
      name: "Detalles de Evento",
      component: EventDetail,
      show:false
    }
  ],
}
const bannerRoutes={
  path: "/banners",
  name: "Banners",
  header: "Banners",
  icon: faImage,
  component: Banners,
  children: null,
}





const defaultRoutes = {
  path: "/*",
  name: "Main",
  icon: faSignInAlt,
  component:Default,
  children: null
};
const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: faSignInAlt,
  children: [
    {
      path: "/auth/login",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/*",
      name: "404 Page",
      component: SignIn,
    },
    
  ],
};




// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  userRoutes,
  reportRoutes,
  bannerRoutes,
  eventRoutes,
  notificationRoutes,
  profileRoutes
];



// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];
export const def = [defaultRoutes];

// All routes
export default [
  dashboardRoutes,
  reportRoutes,
  userRoutes,
  bannerRoutes,
  eventRoutes,
  notificationRoutes,
  profileRoutes
];
