// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_RIGHT_ENABLE = "SIDEBAR_RIGHT_ENABLE";
export const SIDEBAR_RIGHT_DISABLE = "SIDEBAR_RIGHT_DISABLE";
export const SET_USER = "SET_USER";
export const SET_USER_SELECTED = "SET_USER_SELECTED";
export const SET_EVENT = "SET_EVENT";
export const SET_IS_LOGGED = "SET_IS_LOGGED";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";
