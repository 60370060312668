export const setUser = user => (
    {
      type: 'SET_USER',
      payload: user,
    }
  );
export const setIsLogged = value => (
    {
      type: 'SET_IS_LOGGED',
      payload: value,
    }
  );
export const setEvent= value => (
    {
      type: 'SET_EVENT',
      payload: value,
    }
  );
export const setUserSelected= value => (
    {
      type: 'SET_USER_SELECTED',
      payload: value,
    }
  );