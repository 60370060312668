const env={
    envName: process.env.REACT_APP_ENV_NAME,
    apiUrl: process.env.REACT_APP_API_URL,
    filesUrl: process.env.REACT_APP_FILES_URL,
    s3PublicFiles: process.env.REACT_APP_S3_PUBLIC_FILES,
    awsRegion: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  }

export default env