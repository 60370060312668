import { Auth as AmplifyAuth } from "aws-amplify";
import axios from "axios";
import constants from '../config/constants.js'
const API = {
  endpoint: constants.apiUrl,
  get: async (path) => {
    try {
      const idToken = (await AmplifyAuth.currentSession())
        .getIdToken()
        .getJwtToken();

      return await axios.get(`${API.endpoint}/${path}`, {
        headers: {
          Authorization: idToken,
        },
      });
    } catch (err) {
      return { error: "Error while fetching" };
    }
  },
  put: async (path, fields) => {
    try {
      const idToken = (await AmplifyAuth.currentSession())
        .getIdToken()
        .getJwtToken();
      //
      //
      return await axios.put(`${API.endpoint}/${path}`, fields, {
        headers: {
          Authorization: idToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    } catch (err) {
      return { error: "Error while fetching" };
    }
  },
  post: async (path, fields) => {
    try {
      const idToken = (await AmplifyAuth.currentSession())
        .getIdToken()
        .getJwtToken();
      //
      //
      return await axios.post(`${API.endpoint}/${path}`, fields, {
        headers: {
          Authorization: idToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    } catch (err) {
      return { error: "Error while fetching" };
    }
  },
  delete: async (path) => {
    try {
      const idToken = (await AmplifyAuth.currentSession())
        .getIdToken()
        .getJwtToken();

      return await axios.delete(`${API.endpoint}/${path}`, {
        headers: {
          Authorization: idToken,
        },
      });
    } catch (err) {
      return { error: "Error while fetching" };
    }
  },
};



const formatDate = (date) => {
  let formattedDate = new Date(date * 1000);
  let str =
    formattedDate.getMonth() +
    1 +
    "/" +
    formattedDate.getDate() +
    "/" +
    formattedDate.getFullYear();

  return str;
};

const formatHour = (date) => {
  let newDate = new Date(date * 1000);
  var hours = newDate.getHours();
  var minutes = newDate.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};


export { API, formatDate, formatHour };
